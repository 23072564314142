import React from 'react';
import { graphql, Link } from 'gatsby';
import { Button, ButtonsContainer, Cards, SiteMetadata, GoogleAds, TypeButtons, AdvertCards} from '../components';
import { Layout } from '../layouts/Layout';
import { CTABanner } from '../components/CTABanner';
import { isRecent } from '../helper'

const Section = ({ title, description, items, children }) => (
  <div className="pt-8 pb-2">
    <h2 className="font-semibold text-3xl text-indigo-900">
      {title}
    </h2>
    <h3 className="font-base text-base text-gray-600 leading-snug">
      {description}
    </h3>
    <Cards nodes={items} Advert={AdvertCards()} />
    {children}
  </div>
)

export default ({ data, ...props }) => {
  const comingUpNextNodes = data.comingUpNext.nodes.filter(node => !isRecent(node.data.start)).slice(0, 3)
  const latestStreamings = data.latestStreamings.nodes.filter(node => !comingUpNextNodes.some(x => x.data.slug === node.data.slug)).slice(0, 8)
  const callToAction = data.callToAction.nodes[0]
  return (
    <Layout>
      <SiteMetadata
        title="Home"
        description="Theatre streamings and non-bootleg recordings that are being offered online for free, or for a small donation fee, during the COVID-19 pandemic"
        path={props.location.pathname}
      />
      {comingUpNextNodes.length > 0 && <Section title="Coming up next!"
        description="Streamings soon to be available in the next week or two"
        items={comingUpNextNodes}>
      </Section>}

      <Section title="Latest Streamings"
        description="Hot off the press streamings that are available for your #StayAtHome entertainment"
        items={latestStreamings}>
        <ButtonsContainer>
          <Button to="/streamings?ref=home-button">
            See all streamings
          </Button>
        </ButtonsContainer>
      </Section>

      <TypeButtons title="You might also be interested in..."></TypeButtons>

      <GoogleAds slotId="2314545955" />
      <Section title="Recently Expired"
        description="Streamings that were available but expired recently"
        items={data.expired.nodes}>
        <ButtonsContainer>
          <Button to="/past-streamings">
            See all past streamings
          </Button>
        </ButtonsContainer>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery($tableName: String!, $yesterday: Date) {
    callToAction: allDatoCmsBlogpost(filter: {slug: {eq: "best-usb-microphones-for-home-recording-beginners"}}) {
      nodes {
        featureImage {
          url
        }
        title
        slug
      }
    }
    comingUpNext: allAirtable(
      filter: {
        table: {eq: $tableName},
        data: {
          expired: {ne: 1},
          start: {gt: $yesterday}
        }
      },
      sort: {fields: data___start, order: ASC}, limit: 20) {
        nodes {
          data {
            country
            tags
            image {
              ...CardImageFragment
            }
            name
            slug
            summary
            start
            expiry
          }
        }
    }
    latestStreamings: allAirtable(
      filter: { table: { eq: $tableName }, data: { expired: { ne: 1 } } }
      sort: { fields: data___lastModified, order: DESC }
      limit: 11
    ) {
      nodes {
        data {
          country
          tags
          image {
            ...CardImageFragment
          }
          name
          slug
          summary
          start
          expiry
        }
      }
    }
    expired: allAirtable(
      filter: { table: { eq: $tableName }, data: { expired: { ne: 0 } } }
      sort: { fields: data___lastModified, order: DESC }
      limit: 3
    ) {
      nodes {
        data {
          country
          tags
          image {
            ...CardImageFragment
          }
          name
          slug
          summary
          expiry
        }
      }
    }
  }
`;
